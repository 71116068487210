import React from 'react'
import hatch from '../../assets/images/cars/hatch.png'
import sedan from '../../assets/images/cars/sedancab.png'
import suv from '../../assets/images/cars/suvcab.png'
import maxicab from '../../assets/images/cars/maxicab.png'

const Cabs = () => {
  return (
    <div className="container py-5 md:py-8 lg:py-10 mx-auto px-4 md:px-8 lg:px-16">
      <h4 className=" text-3xl font-bold primary-color text-center">Types of Cabs</h4>
      <div className='grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 mt-5 gap-2'>
        <div className='relative hover:scale-110 transition-all'>
            <img src={hatch}/>
            <span className='absolute bottom-[10%] text-lg font-semibold left-[30%]'> Hatchback</span>
        </div>
        <div className='relative hover:scale-110 transition-all'>
            <img src={sedan}/>
            <span className='absolute bottom-[10%] text-lg font-semibold left-[35%]'> Sedan</span>

        </div>
        <div className='relative hover:scale-110 transition-all'>
            <img src={suv}/>
            <span className='absolute bottom-[10%] text-lg font-semibold left-[38%]'> SUV</span>

        </div>
        <div className='relative hover:scale-110 transition-all'>
            <img src={maxicab}/>
            <span className='absolute bottom-[10%] text-lg font-semibold left-[35%]'> Maxi Cab</span>

        </div>


      </div>
    </div>
  )
}

export default Cabs
