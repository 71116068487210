import React from "react";
import img1 from "../../assets/images/ser1.png";
import img2 from "../../assets/images/ser2.png";
import img3 from "../../assets/images/ser3.png";
import img4 from "../../assets/images/ser4.png";
const OurServices = () => {
  return (
    <div>
      <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
        <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
          Why Choose Covai Call Taxi?        </h4>
        <div className="flex flex-col gap-y-[10px] pt-4">
          <h3 className="font-semibold text-3xl  sm:text-2xl">Local Rental Services
          </h3>
              <div className="p-5 border-2 ">
                <div><span className="text-medium font-semibold pr-2">Hourly Rentals:</span>Ideal for quick trips, shopping, or business meetings within Coimbatore.</div>
                <div className="pt-3"><span className="text-medium font-semibold pr-2">Half-Day Rentals:</span>Suitable for those who need a taxi for 4-5 hours, perfect for sightseeing or multiple stops.</div>
                <div className="pt-3"><span className="text-medium font-semibold pr-2">Full-Day Rentals:</span>Great for a full day of travel within the city, covering various locations without the hassle of finding multiple cabs.</div>
              </div>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Travel Packages:</h3>
          <div className="p-5 border-2 ">
                <div><span className="text-medium font-semibold pr-2">Outstation Trips:</span>Covai Call Taxi offers packages for trips to nearby cities and tourist destinations. Popular routes include Coimbatore to Ooty, Coimbatore to Kodaikanal, and Coimbatore to Munnar.</div>
                <div className="pt-3"><span className="text-medium font-semibold pr-2">Airport Transfers:</span>Convenient pickup and drop services to Coimbatore International Airport, ensuring a hassle-free start or end to your journey.
                </div>
                <div className="pt-3"><span className="text-medium font-semibold pr-2">Tour Packages:</span>Customized tour packages for exploring the scenic beauty and cultural heritage around Coimbatore. This includes guided tours to places like Ooty, Wayanad, and Mudumalai.</div>
              </div>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Key Features:</h3>
          <div className="p-5 border-2 ">
                <div><span className="text-medium font-semibold pr-2">24/7 Service:</span>Available around the clock to meet your travel needs at any time of the day.</div>
                <div className="pt-3"><span className="text-medium font-semibold pr-2">Wide Range of Vehicles:</span>From economy cars to luxury sedans and SUVs, they offer a variety of vehicles to suit different preferences and budgets.</div>
                <div className="pt-3"><span className="text-medium font-semibold pr-2">Experienced Drivers:</span>Professional and courteous drivers with good knowledge of local routes and tourist spots.</div>
                <div className="pt-3"><span className="text-medium font-semibold pr-2">Online Booking:</span>Easy and convenient booking through Covai Call Taxi website, with options for advance reservations and immediate bookings.</div>
              </div>
        </div>
        {/* <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Safety:</h3>
          <p>
            Your safety is our top priority. All our drivers undergo rigorous background checks and extensive training to ensure they meet the highest safety standards. Additionally, our vehicles are regularly inspected and sanitized to provide you with a clean and secure travel experience.
          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Customer Satisfaction:</h3>
          <p>
            We value your feedback and strive to exceed your expectations with every ride. Our dedicated customer support team is available 24/7 to assist you with any inquiries or special requests, ensuring your journey with Covai Call Taxi is nothing short of exceptional.                        </p>
        </div> */}



      </div>
      {/* <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
        <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
          Our Service
        </h4>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Drop Taxi Services:</h3>
          <p>
            Whether you need a ride across town or to a neighboring city, our drop taxi services offer a convenient and cost-effective solution for your travel needs.

          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Airport Transfers:</h3>
          <p>
            Start or end your journey hassle-free with our reliable airport transfer services. We'll ensure you reach your destination on time, relaxed, and ready for your flight.

          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Outstation Trips:</h3>
          <p>
            Explore the beauty of Tamil Nadu and beyond with our customizable outstation trip packages. Sit back, relax, and let us take you on an unforgettable journey.
            Book Your Ride Today!
          </p>
        </div>
        <p className="mt-4">
          Experience the ultimate in convenience, reliability, and affordability with Covai Call Taxi. Book your ride today and discover why we're the preferred choice for drop taxi services in Tamil Nadu. Get in touch with us now to plan your next journey!
        </p>
      </div> */}
      <div className="  px-0 sm:px-4 md:px-8 lg:px-16 bg-[#f5f5f5]  py-3 md:py-5 mt-4 md:mt-6 lg:mt-8">
        <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2  text-center">
          <div className=" flex flex-col gap-y-1">
            <img src={img1} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Easy Online Booking
            </p>
            <p>Pickup and Drop on time</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img2} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Professional Drivers
            </p>
            <p>Well Trained years of experience</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img3} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Variety of Prime Brands
            </p>
            <p>Seden, Suv & Innova</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img4} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Secure Online Payment
            </p>
            <p>24 /7 Customer Support</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
