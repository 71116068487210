import WhatsappComponent from "./WhatsappComponent";
import PhoneComponent from "./components/PhoneComponent";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsandCondition from "./pages/TermsandCondition";
import Header from "./components/Header";
import Footer from "./components/footer/Footer";
import ChennaitoCoimbatore from "./components/PopularOutstations/Chennai/ChennaitoCoimbatore";
import ChennaitoBangalore from "./components/PopularOutstations/Chennai/ChennaitoBangalore";
import ChennaitoSalem from "./components/PopularOutstations/Chennai/ChennaitoSalem";
import ChennaitoMadurai from "./components/PopularOutstations/Chennai/ChennaitoMadurai";
import ChennaitoPondicherry from "./components/PopularOutstations/Chennai/ChennaitoPondicherry";
import ChennaitoVellore from "./components/PopularOutstations/Chennai/ChennaitoVellore";
import ChennaitoTrichy from "./components/PopularOutstations/Chennai/ChennaitoTrichy";
import ChennaitoKumbakonam from "./components/PopularOutstations/Chennai/ChennaitoKumbakonam";
import PopularOutstations from "./components/PopularOutstations";
import CoimbatoretoChennai from "./components/PopularOutstations/Coimbatore/CoimbatoretoChennai";
import CoimbatoretoBangalore from "./components/PopularOutstations/Coimbatore/CoimbatoretoBangalore";

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route
            path="/terms-and-conditions"
            element={<TermsandCondition />}
          ></Route>

          <Route path="/one-way-drop-taxi-chennai-to-coimbatore" element={<ChennaitoCoimbatore />}></Route>
          <Route path="/one-way-drop-taxi-chennai-to-bangalore" element={<ChennaitoBangalore />}></Route>
          <Route path="/one-way-drop-taxi-chennai-to-salem" element={<ChennaitoSalem />}></Route>
          <Route path="/one-way-drop-taxi-chennai-to-madurai" element={<ChennaitoMadurai />}></Route>
          <Route path="/one-way-drop-taxi-chennai-to-pondicherry" element={<ChennaitoPondicherry />}></Route>
          <Route path="/one-way-drop-taxi-chennai-to-vellore" element={<ChennaitoVellore />}></Route>
          <Route path="/one-way-drop-taxi-chennai-to-trichy" element={<ChennaitoTrichy />}></Route>
          <Route path="/one-way-drop-taxi-chennai-to-kumbakonam" element={<ChennaitoKumbakonam />}></Route>
    
          <Route path="/one-way-drop-taxi-coimbatore-to-chennai" element={<CoimbatoretoChennai />}></Route>
          <Route path="/one-way-drop-taxi-coimbatore-to-bangalore" element={<CoimbatoretoBangalore />}></Route>

        </Routes>
        {/* <PopularOutstations /> */}
        <Footer />
        <WhatsappComponent />
        <PhoneComponent />
      </BrowserRouter>
    </>
  );
}

export default App;
