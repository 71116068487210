import React from 'react'
import { FaPhone } from "react-icons/fa";
import { IoMail } from "react-icons/io5";

const Contact = (props) => {
  return (
    // <div className="flex gap-x-5 items-center">
    // <div className='flex'> {props.isphone==1 ? <a href ={`tel:${props.contact}`} >{props.image} </a> :
    // <a href={`mailto:${props.contact}`} className=''>{props.image}</a> }    </div>


    // <div className='flex flex-column'>
    //   <span>{props.Label}</span>
    //   {props.isphone == 1 ? <a href={`tel:${props.contact}`} className="" >{props.contact}</a> :
    //     <a href={`mailto:${props.contact}`} className="">{props.contact}</a>}
    // </div></div>
    <div className='flex items-center gap-x-5'>
      < span className=' w-[45px] h-[45px]  rounded-full bg-[#545454] relative'>

      {props.isphone==1 ? < a href ={`tel:${props.contact}`} >{props.image} </a> :
      <a href={`mailto:${props.contact}`} className=' sm-hidden'>{props.image}</a> }       
      </span>
        <span className={` ${
               props.isphone == 1 ?'':' sm-hidden'} `}> {props.Label}
          <br/>    
           {props.isphone == 1 ? <a href={`tel:${props.contact}`} className="" >{props.contact}</a> :
                <a href={`mailto:${props.contact}`} className="sm-hidden">{props.contact}</a>}

        </span>
    </div>

)
}

export default Contact

